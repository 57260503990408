@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {font-family: 'Open Sans', sans-serif;}
.top-bar {height: 29px; color: #ffffff; background-color: #EA7066; font-size: 16px;
    transition: all 0.5s ease-in-out;}
.bg-orange {background-color: #EA7066;}

.alert-wrapper {
    position: fixed;
    right: 0;
    bottom: -15px;
    width: 100%;
    /* height: 100%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 9999;
    background-color: #8d8d8d99;
}
.global-alert {min-width: 397px;display: flex;justify-content: center;}
.loader-show{display: block!important;}
.request-loader {display: flex;justify-content: center;align-items: center;
    position: fixed;top: 0;right: 0;width: 100%;height: 100%;
    z-index: 9999;background-color: #cecece96;
}

.no-list-style {
    list-style: none !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.rsvp-member-td-p {padding: 1px 6px}

.navbar {box-shadow: 1px 1px 5px 0 #ea7066d4;margin: 0;transition: all 0.5s ease-in-out;}
.btn-group-xs>.btn, .btn-xs {--bs-btn-padding-y: 0.2rem!important;--bs-btn-padding-x: 0.4rem!important;
    --bs-btn-font-size: 0.72rem!important;--bs-btn-border-radius: 0.22rem!important;}
.btn-group-xs>.btn, .btn-xxs {--bs-btn-padding-y: 0.15rem!important;--bs-btn-padding-x: 0.25rem!important;
    --bs-btn-font-size: 0.7rem!important;--bs-btn-border-radius: 0.18rem!important;}

.b-radius-4x {border-radius: 4px;}
.br-1px {border-right: 1px solid #e2e2e2!important;}
.text-green {color: #31a66b !important;}
.login-bg {background-image: url('images/bg-img-01.jpg')}

.bg-light1 {background-color: #f1f1f1;}

.sticky {position: fixed;top: 0;width: 100%;z-index: 1000;transition: 0.5s;}

.fs-9x {font-size: 9px;}
.fs-10x {font-size: 10px;}
.fs-12x {font-size: 12px;}
.fs-13x {font-size: 13px;}
.fs-14x {font-size: 14px;}
.fs-15x {font-size: 15px;}
.fs-16x {font-size: 16px;}
.fs-17x {font-size: 17px;}
.fs-18x {font-size: 18px!important;}
.fs-19x {font-size: 19px;}
.fs-20x {font-size: 20px;}
.fs-21x {font-size: 21px;}
.fs-22x {font-size: 22px;}
.fs-24x {font-size: 24px;}
.fs-26x {font-size: 26px!important;}
.fs-28x {font-size: 28px!important;}
.fs-30x {font-size: 30px!important;}
.fs-35x {font-size: 35px;}
.fs-31x {font-size: 31px;}
.fs-33x {font-size: 33px;}
.fs-37x {font-size: 37px;}
.fs-70x {font-size: 70px;}
.fs-80x {font-size: 80px;}
.fs-90x {font-size: 90px;}

.flex-1 {flex: 1;}
.height-100 {height: 100%!important;}

.property-section-break {color: #000000; text-align: center; border-radius: 4px; background-color: #d3d3d3; padding: 1px 6px;
    font-size: 18px;margin: 10px 0;}
.icon-wrap-green {border: 1px solid #31a66b !important; border-radius: 19px;padding: 3px 6px;}

.category {text-align: center;font-size: 18px;padding: 12px 4px;border: 1px solid #5fca8963;border-radius: 27px;
    box-shadow: 1px 2px 2px #65ad81;}
.category-link {width: 137px;height: 24px;padding: 0 5px;line-height: 23px;}
.category:hover {color: #ffffff;background-color: #198754;cursor: pointer;}
.category.active {color: #ffffff;background-color: #198754;}

.btn-category {padding: 12px 5px;box-shadow: 1px 2px 3px #369d5c; font-size: 18px;}

.cart-counter {right: -6px;top: 9px;position: absolute;font-size: 11px;color: #287665;background-color: #5fca8963;padding: 0 5px;border-radius: 19px;}

.item-wrapper {width: 297px;}
.item {height: 100%;overflow: hidden;background-color: #ffffff;transition: 0.5s;box-shadow: 0 0 6px #a05353;}
.item:hover {box-shadow: 0 0 31px #5f3737;}
.flex-item {flex: 1 320px;}
.on-sale-item {position: absolute; top: 10px; left: 10px; color: #ffffff; background-color: #EA7066;
    padding: 7px 14px;border-radius: 22px;}

.item-img-wrapper {width: 100%;height: 209px;overflow: hidden;
    background-size: cover;background-position: center;background-repeat: no-repeat;}
.item-img {width: 100%; height: 100%;transition: 0.5s;}
.item-img:hover {transform: scale(1.3);}
.item-img-wrapper:hover {background-size: 110%;}

.item-detail { padding-top: 14px; text-align: center;}
.cart-button { align-content: flex-end; justify-content: center; }
.cart-button .buttons {text-align: end;}
.item-footer-section {width: 100%; text-align: center;position: absolute; bottom: 1px; right: 0;margin-bottom: 12px;}

.btn.btn-add-cart { color: #198754;; border: 1px solid #369d5c;}
.btn.btn-add-cart:hover {color: #ffffff !important;background: #EA7066 !important;border-color: #EA7066;}

.cart-lists {flex: 1;min-width: 343px;}
.cart-summer {width: 347px}
.cart-border {border: 1px solid #ebebeb;padding: 13px;}

.quick-cart {min-width: 367px}
@media only screen and (max-width: 1031px) {
    .quick-cart {display: none;}
}

@media only screen and (max-width: 997px) {
    .cart-lists {width: 100%;}
    .cart-summer {width: 100%;}
}

@media only screen and (max-width: 767px) {
    .item-img-wrapper {height: 219px;}
}

@media only screen and (max-width: 567px) {
    .item-img-wrapper {height: 219px;}
    .item-wrapper {width: 317px;}
}

.category-item {}
.category-heading {color: #ffffff; text-align: center; padding: 10px; background-color: #25ad69}
.sub-cat-title {font-size: 16px; font-weight: bold; border-bottom: 1px solid #c7c7c7}

.theme-background {
    margin: 0; padding: 0;
    background-image: radial-gradient(circle, #28282824 1px, transparent 1px);
    background-size: 18px 18px;
    background-color: #ffffff;
}
.kiosk-bg-img {
    margin: 0; padding: 0; height: calc(100vh - 36px);
    /*background-image: url('images/writing-1.jpg');*/
    /*background-size: cover;background-position: center;*/
}

.main-content-container {max-width: 100%;margin: auto; padding: 14px 29px; border-radius: 10px; background-color: #ffffff;
    min-height: calc(100vh - 32px);box-shadow: 0 0 20px rgb(207 87 87 / 25%);}
.dotted-background {background-size: 20px 20px;background-image: radial-gradient(#dbdbdb 10%, transparent 0);background-color: #f3f3f3;}
.bg-gradient {background: linear-gradient(to right, #e3e3e3, #feb47b, #ffdf93, #feb47b, #cbd3d1) !important;}
.signature-container canvas { border: 1px solid #969696;border-radius: 4px;}

/* Auth Pages CSS */
.auth-wrapper {display: flex;justify-content: center;flex-direction: column;text-align: left;border-radius: 4px;}
.auth-inner {width: 450px;margin: auto;background: #ffffff;box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);padding: 10px 55px 21px 55px;border-radius: 15px;transition: all .3s;}
.auth-wrapper .form-control:focus {border-color: #167bff;box-shadow: none;}
.auth-wrapper h3 {text-align: center;margin: 0;line-height: 1;padding-bottom: 20px;}
.custom-control-label {font-weight: 400;}
.forgot-password,
.forgot-password a {text-align: right;font-size: 13px;padding-top: 10px;color: #7f7d7d;margin: 0;}
.forgot-password a {color: #167bff;}

@media only screen and (max-width: 567px) {
    .auth-inner {width: auto; margin: 0;padding: 40px 14px 45px 23px;}
}

.waiver-register {max-width: 577px;background-color: #F0F0F0;padding: 15px 34px;border-radius: 6px;margin-bottom: 17px;}
.alert-icon {display: flex; justify-content: center; font-size: 61px;color: #eec830;}
.alert-title {display: flex; justify-content: center; font-size: 22px; font-weight: bold;margin-top: 13px;}
.req-label:after {color: #d00;content: "*";position: absolute;margin-left: 3px;}

.waiver-mem-detail-sec{background-color: #f0f0f0;border-radius: 4px;padding: 7px 16px;}
.m-width-73x {min-width: 73px;}

.guardian-title{font-size: 18px; margin-bottom: 10px;}

.checkbox-lg .form-check-input {transform: scale(1.4);border: 1px solid #bebebe;}
.checkbox-xl .form-check-input {transform: scale(1.6);border: 1px solid #bebebe;}
.custom-checkbox .form-check-input {transform: scale(1.5);border-color: #597eb6;}
.custom-checkbox .form-check-label {margin-left: 6px;}
.select-error {border: 1px solid red;border-radius: 6px;}

.table-xs {font-size: 14px!important;}
.text-theme {color: #EA7066!important;}
.text-light-green {color: #5fca89!important;}
.text-dark-light {color: #5a5555!important;}
.bg-light-green {background-color: #5fca89!important;}

.cart-td-head tr td {background-color: #ebebeb!important;}
.cart-td-bg-light-green tr td {background-color: #5fca89!important;}
.bb-1px {border-bottom: 1px solid #dedede}
.quantity-icon {}
.quantity-title {min-width: 27px;border-bottom: 1px solid #cacaca;}

.item-quantity {padding: 1px 8px; font-size: 16px;border: 1px solid darkgrey;border-radius: 19px;height: 31px;align-items: center;line-height: 10px;}
.section-heading-1 { border-bottom: 1px solid #cacaca; font-weight: bold; font-size: 15px;}

.bookings-list {padding: 10px; background-color: #ebebeb; border-radius: 4px;margin-bottom: 7px;}
.space-slot {width: 71px; padding: 4px 2px; color: #ffffff; background-color: #5dc591; text-align: center;font-size: 14px;}
.space-slot.disabled {background-color: #b3b3b3!important;}
.space-slot.active {background-color: #176e43;}
.space-slot:hover {cursor: pointer}

.bookable-space {width: 100%;padding: 8px 8px;color: #f5f5f5;background-color: #dd9b96;
    text-align: center;font-size: 16px;border-bottom: 3px solid #db5e53;
    border-bottom-left-radius: 14px;border-bottom-right-radius: 14px;}

.rsvp-form {margin: auto;}
.rsvp-form-header {width: 70%; margin: auto;}
.rsvp-form-members {width: 100%; margin: auto;}
.rsvp-form-member {margin-bottom: 10px; padding: 10px 6px; border-bottom: 1px solid #c5c5c5;}
.rsvp-form-member-item {min-width: 207px;}

.rsvp-item {margin-bottom: 18px;background-color: #f3f3f3;font-size: 16px;border: 1px solid #c7c7c7;border-radius: 6px;}
.rsvp-item-header {padding: 10px 16px; margin-bottom: 10px;background-color: #e1e1e1;border-radius: 6px;}
.rsvp-members-table {font-size: 16px;}
.rsvp-members-table td {padding: 3px 2px;}
.rsvp-waiver-status {display: flex; align-items: center; font-size: 16px;}


.bookable-space-img {height: 73px; text-align: center; position: relative; border: 1px solid #dd9b96;
    border-top-left-radius: 10px;border-top-right-radius: 10px;}
.bookable-space-img img {width: 100%;height: 100%; object-fit: cover; border-top-left-radius: 10px;border-top-right-radius: 10px;}
.bs-images {font-size: 14px;position: absolute;bottom: 0;width: 100%;background-color: #b2d4c9c7;}

.cart-list-item {padding: 14px 12px; margin-bottom: 7px;background-color: #e9e9e9;border-radius: 4px;}
.cart-property-item {padding: 5px 4px;}

.bookable-space:hover {cursor: pointer}
.bookable-space.active {background-color: #db5e53;}
.cursor-pointer:hover {cursor: pointer;}

.hide-on-mobile {display: block!important; visibility: visible;}
.hide-on-desktop {display: none!important; visibility: hidden;}
.react-datepicker-wrapper {width: 100%;}

.checkout-section {width: 400px;}
.custom-date-picker-container {position: relative;display: inline-block;width: 421px;}
.link {color: #0a58ca;}
.link:hover {color: #2f2fc7;cursor: pointer;text-decoration: underline;}

.global-payment-iframe {width: 507px}
.bookable-service-section {background-color: #ffffff;border-radius: 6px;padding: 6px 15px 6px 6px;}
.bookable-service-cart {}
.bs-list-item1 {}
.bs-list-item2 {flex: 1}
.space-item{flex: 1 1 46%}

.cart-no-item {background-color: #eaeaea;padding: 16px;font-size: 18px;font-weight: bold;
display: flex; justify-content: center;}

.checkbox-lg {}

.desc-color-box-selected {width: 20px; height: 20px; border-radius: 6px; background-color: #176E43;}
.desc-color-box-available {width: 20px; height: 20px; border-radius: 6px; background-color: #5DC591;}
.desc-color-box-unavailable {width: 20px; height: 20px; border-radius: 6px; background-color: #B3B3B3;}
.bookable-property-title {background-color: #969fdd;color: #ffffff;padding: 1px 8px;border-radius: 3px;}
.booking-note {background-color: #edcdca;padding: 11px 8px;border-radius: 3px;text-align: center;font-size: 18px;}

.custom-input-style label {color: #4e4e4e;font-weight: bold!important;}
.custom-input-style input{border-bottom: 2px solid #5e7ae8 !important;}
.custom-input-style select{border-bottom: 2px solid #44b782 !important;}
.custom-input-style textarea{border-bottom: 2px solid #ea7066 !important;}


.custom-nave-pills-header{text-align: center;padding: 10px;color: #ffffff;
    font-size: 17px;font-weight: bold;background-color: #babfbc;
    border-top-left-radius: 6px;border-top-right-radius: 6px;}
.custom-nave-pills{background-color: #e7e7e7;}
.custom-nave-pills .nav-item{border-bottom: 1px solid #ffffff;}

.custom-nave-pills .nav-link {color: #252525;}
.custom-nave-pills .nav-link{border-radius: 0;}
.custom-nave-pills .nav-link:focus, .custom-nave-pills .nav-link:hover {color: #44b782;}
.custom-nave-pills .nav-link.active, .custom-nave-pills .show > .nav-link {color: #ffffff;background-color: #EA7066;}
.navbar-nav .nav-link:hover {cursor: pointer!important;}

.profile-list {padding: 16px 0 4px 0;border-bottom: 1px solid #d3d3d3;}
.data-list {
    padding: 10px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    background-color: #ededed;
}
.list-status {padding: 4px 10px; border-radius: 15px;}
.thead-light th {color: #302f2f; background-color: #e5e5e5;}
.thead-theme th {color: #ffffff; background-color: #44b782;}

.bg-light-3 {--bs-bg-opacity: 1;background-color: #f1f1f1 !important;}
.inner-heading {color: #6e6c6c;font-size: 16px;font-weight: bold; margin-bottom: 6px;border-bottom: 1px solid #e9e9e9;}

.radio-lg input {width: 1.5em;height: 1.5em;margin-top: 0;margin-right: 10px;border: 1px solid #ea7066;}

/* For tablet screen */
@media only screen and (max-width: 997px) {
    .space-item{flex: 1 1 100%}
}

/* For mobile width only */
@media only screen and (max-width: 567px) {
    .top-bar {font-size: 14px;}
    .cart-counter {right: -8px; top: 10px;}
    /*.bookable-service-section {max-width: 321px;}*/
    .bs-list-item1 {width: 100%; display: flex; justify-content: center;}
    .bs-list-item2 {flex: 1 1 100%; margin-bottom: 6px;}
    .bs-list-item2 .slots-list{justify-content: center;}
    .hide-on-mobile {display: none!important; visibility: hidden;}
    .hide-on-desktop {display: block!important; visibility: visible;}
    .item-detail{text-align: center;}
    /*.item-img {text-align: center;}
    .item-img img {max-height: 153px;}*/
    .cart-button .buttons {text-align: center;}
    .guardian-title{font-size: 15px; margin-bottom: 10px;}

    .checkout-section {width: 299px;}
    .custom-date-picker-container {width: 257px;}
    .global-payment-iframe {width: 299px}

    .rsvp-form-header {width: 100%; margin: auto;}
    .rsvp-item {font-size: 15px;}
    .rsvp-members-table {font-size: 13px;}
    .rsvp-waiver-status {font-size: 22px;}
    .rsvp-form-members {font-size: 13px;}
}


div:where(.swal2-container) h2:where(.swal2-title) {padding: .1em 1em 0;}
div:where(.swal2-container) .swal2-html-container {margin: .3em 1.6em .3em;}
div:where(.swal2-container) div:where(.swal2-actions) {margin: .6em auto 0;}


.price-cell {
    display: flex;
    justify-content: flex-start;
}
.price-text {
    text-align: right;
    flex-grow: 1;
    white-space: nowrap;
}

/* Input type date custom style */

.custom-date-picker-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}
.booking-date-display {
    position: absolute;
    left: 13px;
    top: 8px;
}

.MuiFormControl-root {width: 100%;background-color: #cccccc;}
.custom-mui-datepicker {width: 100%; background-color: #cccccc;}

.custom-date-picker-input {
    padding-right: 30px; /* Adjust padding to prevent text overlap with icon */
    width: 200px; /* Custom width for the date picker input */
}

.custom-date-picker {
    width: 200px; /* Custom width for the date picker pop-up */
}


/*  */
.navbar-toggler-icon {width: 1.3em;height: 1.3em;}
.navbar-toggler {padding: 4px 10px;}

.category-select .css-1b62z6r-control {border: 2px solid #9ecfb3;}
.category-select .css-1b62z6r-control:hover {border-color: #9ecfb3;}
.category-select .css-qwsg14-control {box-shadow: 0 0 0 1px #9ecfb3}
.category-select .css-qwsg14-control:hover {border-color: #9ecfb3;}


/* React Dates css */
.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
    background: #ededed;border: 1px solid #d3d3d3;color: #82888a;}
.CalendarMonth_table tbody, td, tfoot, th, thead, tr {border-color: #e5e5e5;border-width: 1px;}
.DateInput {width: 322px;}
.DateInput_input {text-align: center;font-size: 18px;font-weight: bold;border-bottom: 1px solid #00A699;}


/* Sweet alert */
div:where(.swal2-icon) {width: 3em!important;height: 3em!important;margin: 1.5em auto .5em;}
div:where(.swal2-icon) .swal2-icon-content {font-size: 2.75em!important;}
div:where(.swal2-container) h2:where(.swal2-title) {padding: .2em 1em 0 !important;}
div:where(.swal2-container) .swal2-html-container {margin: 0.3em 1.6em .3em !important;}


/* Bootstrap Carousel */
.carousel-img {
    width: 100%; /* Ensure it takes full width of the container */
    height: 400px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.slider-container {
    text-align: center;
}

.main-img {
    width: 100%;
    height: 427px;
    object-fit: cover; /* Ensures consistent image sizing */
}

/* Thumbnail styling */
.thumbnail-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail {
    width: 80px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.thumbnail:hover {
    transform: scale(1.1);
}

/* Highlight active thumbnail */
.active-thumbnail {
    border: 2px solid #007bff;
    transform: scale(1.1);
}

@media only screen and (max-width: 667px) {
    .main-img {
        height: 273px;
    }
}